import { css } from 'styled-components';
// style-utils
export const media = {
  pc: (...args) => css`
    @media (min-width: 813px) {
      ${css(...args)};
    }
  `,
  sp: (...args) => css`
    @media (max-width: 812px) {
      ${css(...args)};
    }
  `
};