import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import bgPatternPng from "../images/bg-pattern.png"
import { media } from '../components/style-utils';


const Footer = styled.footer`
  position: relative;
`;
const FooterInner = styled.div`
  position: relative;
  z-index: 0;
  ${media.sp`
    margin-bottom: 13vw;
  `}
  > .-inner {
    position: relative;
    height: 84px;
    margin: 0 auto;
    padding-left: 40px;
    display: flex;
    align-items: center;
    ${media.sp`
      height: 12vw;
      padding-left: 2vw;
    `}
    a {
      font-family: 'Dosis', sans-serif;
      margin: 2px 20px 0 0;
      letter-spacing: .175em;
      color: ${val.BLACK};
      text-decoration: none;
      border-bottom: 1px solid  ${val.BLACK};
      font-size: 10px;
      transition: color .2s, border-color .2s;
      ${media.sp`
        font-size: 2vw;
        margin: 0 2vw 0 0;
        white-space: nowrap;
      `}
      &:hover {
        color: ${val.HOVER};
        border-color: ${val.HOVER};
      }
    }
    &:after {
      content: '';
      height: 42px;
      width: 1px;
      background: ${val.WHITE};
      display: inline-block;
      position: absolute;
      top: 0;
      right: 17px;
    }
  }
  .copy {
    font-size: 10px;
    margin: 5px 0 0 10px;
    font-family: 'Dosis', sans-serif;
    letter-spacing: .185em;
    transform: scale(.8);
    color: ${val.BLACK};
    white-space: nowrap;
  }
`;
const BoxTotop = styled.div`
  position: fixed;
  right: 150px;
  bottom: 36px;
  transition: bottom .3s;
  ${media.sp`
    right: 20vw;
    bottom: 12vw;
    body.-agree & {
      bottom: 3vw;
    }
  `}
`;
const ArrowToTop = styled.a`
  position: relative;
  right: -8px;
  bottom: -16px;
  display: inline-block;
  cursor: pointer;
  svg {
    width: 47px;
    height: 47px;
    transform: rotate(90deg) scale(-1, 1) translateX(4px);
    fill: ${val.LOGO};
    transition: fill .2s;
    &:hover {
      fill: ${val.HOVER};
    }
  }
`;
const FooterContact = styled.div`
  position: fixed;
  z-index: 0;
  right: 220px;
  bottom: 36px;
  transition: bottom .3s;
  ${media.sp`
    right: 33vw;
    bottom: 12vw;
    body.-agree & {
      bottom: 2vw;
    }
  `}
`;
export default ({contactLink}) => {
  return (
    <Footer style={{backgroundImage: `url(${bgPatternPng})`}}>

      <FooterInner>
        <div className="-inner">
          <Link to="/about/">事務所概要</Link>
          <Link to="/terms/">利用規約</Link>
          <p className="copy">Copyright &copy; 2018 CHOJU-KABOKU-EN</p>
        </div>
      </FooterInner>
      <BoxTotop>
        <ArrowToTop href="#wrap">
          <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
        </ArrowToTop>
      </BoxTotop>
      <FooterContact>
        <Link to={contactLink} className="btn en btn-arrow js-btn-hideandseek"  activeClassName="active">
        <span className="text">お問い合わせ</span>
        <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
        </Link>
      </FooterContact>
    </Footer>
  )
}