import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import { media } from '../components/style-utils';


const Menu = styled.div`
  position: fixed;
  top: 0;
  right: -360px;
  width: 360px;
  height: 100vh;
  background: ${val.BG};
  transition: right .5s;
  overflow-y: auto;
  &.-open {
    right: 0;
  }
  > .-content {
    margin: 0;
    padding: 60px 20px 20px 60px;
  }
  .link {
    display: block;
    position: relative;
    &.-sub {
      .icon {
        top: 0;
      }
    }
  }
  .link-box {
    margin-bottom: 30px;
    display: block;
    &.-sub {
      margin-top: 54px;
    }
    .-title {
      display: block;
    }
    .-text {
      display: block;
      font-size: 11px;
      margin-left: 5px;
      color: ${val.DARK};
    }
    .icon {
      position: absolute;
      right: 10px;
      top: 0.4em;
    }
  }
  .link-logo {
    svg {
      transition: fill .2s;
      &:hover {
        fill: ${val.HOVER};
      }
    }
  }
`;
const Btnmenu = styled.button`
  position: fixed;
  bottom: 30px;
  right: 36px;
  width: 68px;
  height: 68px;
  background: ${val.BUTTON};
  border-width: 0;
  outline-width: 0;
  cursor: pointer;
  transition: background .2s;
  ${media.sp`
    width: 10vw;
    height: 10vw;
    right: 3vw;
    bottom: 11.5vw;
    transition: bottom .3s;
    body.-agree & {
      bottom: 1.4vw;
    }
  `}
  .-icon {
    display: inline-block;
    position: absolute;
    background: ${val.WHITE};
    height: 3px;
    display: inline-block;
    opacity: 1;
    transition: top .3s, opacity .3s, transform .3s;
    &.-icon-1 {
      top: 19px;
      left: 16px;
      width: 32px;
      ${media.sp`
        height: 2px;
        top: 2.2vw;
        left: 1.6vw;
        width: 6.4vw;
      `}
    }
    &.-icon-2 {
      top: 22px;
      left: 48px;
      width: 5px;
      ${media.sp`
        height: 2px;
        top: 2.8vw;
        left: 7.6vw;
        width: 1vw;
      `}
    }
    &.-icon-3 {
      top: 32px;
      left: 16px;
      width: 37px;
      ${media.sp`
        height: 2px;
        top: 5vw;
        left: 1.6vw;
        width: 7vw;
      `}
    }
    &.-icon-4 {
      top: 32px;
      left: 16px;
      width: 37px;
      ${media.sp`
        height: 2px;
        top: 5vw;
        left: 1.6vw;
        width: 7vw;
      `}
    }
    &.-icon-5 {
      top: 49px;
      left: 16px;
      width: 13px;
      ${media.sp`
        height: 2px;
        top: 7.4vw;
        left: 6vw;
        width: 2.5vw;
      `}
    }
    &.-icon-6 {
      top: 46px;
      left: 29px;
      width: 24px;
      ${media.sp`
        height: 2px;
        top: 8vw;
        left: 1.6vw;
        width: 4.5vw;
      `}
    }
  }
  &:hover {
    ${media.pc`
      background: ${val.HOVER};
      .-icon {
        &.-icon-2 {
          top: 19px;
        }
        &.-icon-5 {
          top: 46px;
        }
      }
    `}
  }
  &.-open {
    .-icon {
      &.-icon-1,
      &.-icon-2,
      &.-icon-5,
      &.-icon-6 {
        opacity: 0;
      }
      &.-icon-3 {
        top: 33px;
        left: 16px;
        width: 36px;
        transform: rotate(45deg);
        ${media.sp`
          top: 4.8vw;
          left: 1.2vw;
          width: 8vw;
        `}
      }
      &.-icon-4 {
        top: 33px;
        left: 16px;
        width: 36px;
        transform: rotate(-45deg);
        ${media.sp`
          top: 4.8vw;
          left: 1.2vw;
          width: 8vw;
        `}
      }
    }
  }
`;

const handleClick = (el) => {
  document.querySelector('.js-menu-btn').classList.toggle('-open');
  document.querySelector('.js-menu').classList.toggle('-open');
};

export default () => {
  return (
    <div>
      <Menu className="js-menu">
        <ul className="-content js-menu-content">
          <li className="link-box">
            <Link className="link" to="/web/">
              <span className="-title">ホームページ制作</span>
              <span className="-text">ホームページ制作のプランと価格　</span>
              <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
            </Link>
          </li>
          <li className="link-box">
            <Link className="link" to="/operate/">
              <span className="-title">ホームページ運用</span>
              <span className="-text">運用・改善・アクセスアップ・SEO対策</span>
              <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
            </Link>
          </li>
          <li className="link-box">
            <Link className="link" to="/design/">
              <span className="-title">デザイン</span>
              <span className="-text">名刺、チラシ、パンフレットなど</span>
              <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
            </Link>
          </li>
          <li className="link-box">
            <Link className="link" to="/faq/">
              <span className="-title">よくあるご質問</span>
              <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
            </Link>
          </li>
          <li className="link-box">
            <Link className="link" to="/#contact">
              お問い合わせ
              <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
            </Link>
          </li>
          <li className="link-box">
            <Link className="link" to="/blog/">
              ブログ・お知らせ
              <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
            </Link>
          </li>
          <li className="link-box -sub"><Link className="link -sub" to="/about/">事務所概要<svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg></Link></li>
          <li className="link-box"><Link className="link -sub" to="/terms/">利用規約<svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg></Link></li>
          <li className="link-box"><Link className="link -sub" to="/privacy/">プライバシーポリシー<svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg></Link></li>
          <li className="link-box"><Link className="link -sub" to="/law/">特定商取引法に基づく表記<svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg></Link></li>
        </ul>
        <Link className="link-logo" to="https://choju-kaboku-en.com/" target="_blank">
          <svg className="logo-menu"><use xlinkHref="#logo"></use></svg>
        </Link>
      </Menu>
      <Btnmenu className="js-menu-btn" type="button" onClick={({target})=> handleClick(target)}>
        <span className="-icon -icon-1"></span>
        <span className="-icon -icon-2"></span>
        <span className="-icon -icon-3"></span>
        <span className="-icon -icon-4"></span>
        <span className="-icon -icon-5"></span>
        <span className="-icon -icon-6"></span>
      </Btnmenu>
    </div>
  )
}
