export const val = {
  sp: `(max-width: 812px)`,
  pc: `(min-width: 812.1px)`,
  width_pc: `960px`,
  BLACK: `#333`,
  LOGO: `#3E3A39`,
  WHITE: `#fff`,
  BG: `#eaeaea`,
  BG2: `#eee`,
  BG3: `#ececec`,
  HOVER: `#d76933`,
  PRIMARY: `#3b914e`,
  SECONDARY: `#3c3b91`,
  FOCUS: `#fddfd1`,
  BLANK: `#596f49`,
  BUTTON: `#474747`,
  BORDER: `#999`,
  GRAY: `#d8d8d8`,
  DARK: `#878181`
};