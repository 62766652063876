import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import { media } from '../components/style-utils';

// STYLE
const Header = styled.header`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${val.WHITE};
`;
const HeaderLogo = styled.div`
  a {
    color: ${val.LOGO};
    text-decoration: none;
    font-family: 'Dosis', sans-serif;
    font-size: 12px;
    transition: color .2s;
    &:hover {
      color: ${val.HOVER};
    }
  }
`;
const HeaderText = styled.div`
  padding: 0;
  font-size: 12px;
  ${media.sp`
    font-size: 2.5vw;
  `}
`;
// JSX
export default () => (
  <Header>
    <HeaderLogo>
      <Link to={`/`}>
        TAN-ZENT
      </Link>
    </HeaderLogo>
    <HeaderText>
      ホームページ作成・運用・デザイン　<br className="sp" />格安で大手WEB制作会社のクオリティ<span className="pc">　神奈川 横浜 横須賀 鎌倉 葉山 逗子 三浦 藤沢 湘南 東京</span>
    </HeaderText>
  </Header>
)
