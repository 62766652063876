import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { val }  from '../components/cssvalue'
import { media } from '../components/style-utils';

const Global = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    //font-family: 'HonMinSKok-L', 'Yu Mincho', 'Hiragino Mincho ProN', 'メイリオ', 'Meiryo', serif;
    font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraKakuProN-W3', 'YuGothic';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    font-weight: 400;
    font-kerning: normal;
    font-display: block;
    font-feature-settings: 'palt';
    letter-spacing: .08em;
    line-height: 1.75;
    color: #333;
    ${media.sp`
      font-size: 3.8vw;
    `}
  }
  @media ${val.pc} {
    .sp {
        display: none !important;
      }
    }
  @media ${val.sp} {
    .pc {
      display: none !important;
    }
  }
  .en {
    font-family: 'Dosis', sans-serif;
  }
  .bold {
    font-weight: 700;
  }
  strong {
    color: ${val.HOVER};
  }
  .primary {
    color: ${val.PRIMARY};
  }
  .secondary {
    color: ${val.SECONDARY};
  }
  body {
    margin: 0;
    color: ${val.BLACK};
    //background: ${val.BG3};
  }
  .logo {
    fill: ${val.WHITE};
  }
  .-p {
    font-size: 14px;
    letter-spacing: .03em;
    line-height: 2.3;
    ${media.sp`
      font-size: 3.38vw;
    `}
  }
  .link {
    color: ${val.BLACK};
    text-decoration: none;
    font-family: 'Dosis', sans-serif;
    font-size: 18px;
    letter-spacing: .2em;
    transition: color .3s;
    ${media.sp`
      font-size: 4.35vw;
    `}
    > .icon {
      width: 20px;
      height: 20px;
      margin-left: 9px;
      vertical-align: text-bottom;
      transition: transform .3s, fill .3s;
      fill: ${val.BLACK};
    }
    &.-sub {
      font-size: 13px;
      letter-spacing: .18em;
      > .icon {
        vertical-align: -5px;
      }
    }
    &:hover {
      color: ${val.HOVER};
      > .icon {
        transform: translateX(6px);
        fill: ${val.HOVER};
      }
    }
  }
  .btn-arrow {
    font-size:18px;
    padding: 5px 17px 4px 15px;
    font-family: 'Dosis', sans-serif;
    font-size: 1.125rem;
    letter-spacing: .21em;
    ${media.sp`
      font-size: 3.6vw;
      padding: 1.2vw 4.1vw 1vw 3.6vw;
    `}
    .icon {
      height: 21px;
      width: 21px;
      vertical-align: -4px;
      margin-left: 2px;
    }
  }
  .btn {
    display: inline-block;
    background: ${val.BUTTON};
    color: ${val.WHITE};
    border-width: 0;
    outline-width: 0;
    transition: background-color .2s;
    cursor: pointer;
    font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraKakuProN-W3', 'YuGothic';
    text-decoration: none;
    .icon {
      fill: ${val.WHITE};

    }
    &:hover,
    &:focus {
      background-color: ${val.HOVER};
    }
  }
  .section {
    > .-inner {
      max-width: ${val.width_pc};
      margin: 0 auto;
      position: relative;
      > .title {
        font-size: 18px;
        padding: 5px 16px;
        display: inline-block;
        transform: rotate(90deg);
        background: ${val.WHITE};
        @extend .en;
        font-weight: 400;
        letter-spacing: .18em;
        ${media.sp`
          font-size: 3.6vw;
          padding: 1.2vw 3.2vw;
        `}
      }
    }
    .-p {
      margin-bottom: 0;
    }
    .-btnbox {
      text-align: center;
      margin-top: 10px;
    }
  }
`
const GlobalCSS = () => {
  return <Global />
}

export default GlobalCSS