import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { createGlobalStyle } from 'styled-components'
import Globalstyle from '../components/globalstyle'
import { val }  from '../components/cssvalue'
import Header from "../components/header"
import Footer from "../components/footer"
import Menu from "../components/menu"
import Svgicons from "../components/svgicons"
import { media } from '../components/style-utils';

// styles
import bgPatternPng from "../images/bg-pattern.png"
const Lead = styled.div`
  color:  ${val.LOGO};
  font-family: 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraKakuProN-W3', 'YuGothic';
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    transform: rotate(90deg);
  }
  .-p {
    margin-right: 20px;
    font-size: 16px;
    ${media.sp`
      margin-right: 5vw;
      font-size: 3.6vw;
      line-height: 1.6;
    `}
  }
  .-btn-contact {
    text-align: center;
  }
`;
const Wrap = styled.div`
  padding-bottom: 40px;
  transition: padding .3s;
  ${media.sp`
    padding-bottom: 10vw;
  `}
  body.-agree & {
    padding-bottom: 0;
  }
`;
const BtnContact = styled(Link)`
  &.btn {
    width: 90px;
    height: 90px;
    box-sizing: border-box;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    letter-spacing: .21em;
    ${media.sp`
      font-size: 4vw;
      width: 18vw;
      height: 18vw;
      line-height: 1.7;
    `}
  }
`;

const Cookie = styled.div `
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  padding: 0 10vw;
  font-size: 12px;
  color: #fff;
  background: rgba(0,0,0,.7);
  box-sizing: border-box;
  visibility: visible;
  ${media.sp`
    justify-content: space-around;
    font-size: 2vw;
    height: 10vw;
  `}
  body.-agree & {
    display: none;
  }
  a {
    color: #fff;
  }
  .-agree {
    color: #fff;
    background: dodgerblue;
    padding: .3em 1.5em;
    cursor: pointer;
    margin-left: 10px;
  }
`;
const CookieOk = e => {
  document.querySelector('body').classList.add('-agree')
}

const Layout = ({ location, title, children }) => {
  let contactLink = ['/design/', '/web/', '/dessign/', '/operate/', '/faq/'].includes(location.pathname) ? location.pathname : '/';
      contactLink = contactLink + '#contact';
  return (
    <Wrap id="wrap">
      <Globalstyle />
      <Header />
      <main>
        <Lead style={{backgroundImage: `url(${bgPatternPng})`}}>
          <p className="-p">
            <strong>お見積もり無料・ご相談無料</strong><br />
            ホームページ制作から運用、<br />名刺やパンフレットのデザインまで、<br />
            お気軽にお問い合わせください。
          </p>
          <p className="-btn-contact" key={contactLink}>
            <BtnContact to={contactLink} className="btn"  activeClassName="active">
              <span className="text">お問い<br />合わせ</span><br />
            </BtnContact>
          </p>
        </Lead>
        {children}
      </main>
      <Footer contactLink={contactLink} />
      <Menu />
      <Svgicons />
      <Cookie>
        <p className="-text">当サイトではCookieを使用しています。<br className="sp" />詳しくは「<Link to="/privacy/">プライバシーポリシー</Link>」をご覧ください。</p>
        <a className="-agree" onClick={CookieOk}>OK</a>
      </Cookie>
    </Wrap>
  )
}
export default Layout
